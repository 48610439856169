import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * Regular clicks on links/buttons.
 *
 * @type {string}
 */
export const clickEvent = 'eb_click'

/**
 * @param {string} who -   The element.(id) that has been clicked on.
 * @param {string} what -  The type of element that has been clicked on (radio, button).
 * @param {number} value - Optional numeric value (bool/weight).
 */
export function emitClickEvent(who, what, value) {
  const scope = getEventScope()
  emitClickEventInScope(scope, who, what, value)
}

/**
 * Emit a click event with specific scope. Useful when it's not safe to determine the scope
 * on the fly due to race conditions with changes to the scope.
 *
 * @param {object} scope
 * @param {string} who -   The element.(id) that has been clicked on.
 * @param {string} what -  The type of element that has been clicked on (radio, button).
 * @param {number} value - Optional numeric value (bool/weight).
 */
export function emitClickEventInScope(scope, who, what, value) {
  emit(clickEvent, {
    who,
    what,
    value,
    scope,
  })
}

/**
 * @param {Function} callback
 */
export function onClickEvent(callback) {
  bindOn(clickEvent, callback)
}
