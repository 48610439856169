import { EventBus } from 'chimera/all/plugins/eventbus'
import { ConsentLevel } from 'chimera/all/plugins/eventbus/events'

/**
 * Determines the consent level based on services consent and essential status.
 * @param {Array} services
 * @returns {string}
 */
const determineConsentLevel = (services) => {
  const totalServices = services.length
  const consentedServices = services.filter(
    (service) => service.consent?.status,
  )
  const essentialServices = services.filter((service) => service.isEssential)

  if (totalServices === consentedServices.length) {
    return ConsentLevel.YES
  }

  const isPartiallyYes =
    consentedServices.length > essentialServices.length &&
    consentedServices.length < totalServices

  return isPartiallyYes ? ConsentLevel.PARTIALLY_YES : ConsentLevel.NO
}

export const handleConsentEvent = async () => {
  try {
    const services = await getServicesBaseInfo()
    const level = determineConsentLevel(services)

    EventBus.emitConsentEvent({
      level,
      services,
    })
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(
      new Error('Error in handleConsentEvent'),
      'handleConsentEvent',
    )
  }
}

/**
 * Checks if the user has consent for the required services.
 * @param {string[]} requiredServiceIds
 * @returns {Promise<boolean>}
 */
export async function hasConsentForServices(requiredServiceIds) {
  const isEventTriggered = await new Promise((resolve) => {
    const timeoutId = setTimeout(() => resolve(true), 2000)

    window.addEventListener(
      'ucEvent',
      () => {
        clearTimeout(timeoutId)
        resolve(true)
      },
      { once: true },
    )
  })

  if (!isEventTriggered || !window.UC_UI) {
    return false
  }

  try {
    const services = await getServicesBaseInfo()
    const consentedServices = services.filter(
      (service) =>
        service.consent?.status && requiredServiceIds.includes(service.id),
    )

    return consentedServices.length === requiredServiceIds.length
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(error, 'hasConsentForServices')
    return false
  }
}

/**
 * Retrieves the services base info using the Usercentrics API.
 * @returns {Promise<[]>}
 */
function getServicesBaseInfo() {
  return runUsercentricsFunction(
    'getServicesBaseInfo',
    'UC_UI.getServicesBaseInfo is not available',
  )
}

/**
 * Opens the second layer of consent UI.
 */
export function openSecondLayer() {
  runUsercentricsFunction(
    'showSecondLayer',
    'UC_UI.showSecondLayer is not available',
  )
}

/**
 * Restarts the embeddings.
 */
export function showEmbeddings() {
  runUsercentricsFunction(
    'restartEmbeddings',
    'UC_UI.restartEmbeddings is not available',
  )
}

/**
 * Runs a Usercentrics function and handles errors.
 * @param {string} functionName
 * @param {string} errorMessage
 * @returns {void|Promise<any>}
 */
async function runUsercentricsFunction(functionName, errorMessage) {
  if (typeof window.UC_UI?.[functionName] !== 'function') {
    EventBus.emitErrorAppErrorEvent(new Error(errorMessage), functionName)
    return null
  }

  try {
    return await window.UC_UI[functionName]()
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(error, functionName)
    return null
  }
}

/**
 * Installs the Usercentrics script.
 * @param {string} usercentricsId
 */
export function installUsercentricsScript(usercentricsId) {
  if (!usercentricsId) {
    EventBus.emitErrorAppErrorEvent(
      new Error('Usercentrics ID is not provided'),
      'installUsercentricsScript',
    )
    return
  }

  if (!document.getElementById('usercentrics-cmp')) {
    const usercentricsScript = document.createElement('script')
    usercentricsScript.id = 'usercentrics-cmp'
    usercentricsScript.src = '//app.usercentrics.eu/browser-ui/latest/loader.js'
    usercentricsScript.setAttribute('data-settings-id', usercentricsId)
    usercentricsScript.async = true
    document.head.appendChild(usercentricsScript)
  }
}
