import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'
import { emit } from './emit'

/**
 * @type {string}
 */
export const visibilityEvent = 'eb_visibility'

/**
 * When targeted element is visible in the viewport, an event will fire.
 *
 * @param {string} who -   The element.(id) that has become visible.
 * @param {string} what -  If element is visible or not. (yes / no)
 * @param {number} value - Optional numeric value (bool/weight).
 */
export function emitVisibilityEvent(who, what, value) {
  emit(visibilityEvent, {
    who,
    what,
    value,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 */
export function onVisibilityEvent(callback) {
  bindOn(visibilityEvent, callback)
}
