import Vue from 'vue'
import { ExperimentSource } from 'chimera/all/mixins/usesExperiments'
import { EventBus } from 'chimera/all/plugins/eventbus'

const getDefaultState = () => {
  return {
    assigned: [],
    queued: [],
    override: {},
    experimentList: [],
  }
}

export const state = getDefaultState

export const getters = {
  /**
   * Get all experiments the visitor participates in.
   *
   * @param {object} state
   * @returns {*}
   */
  all: (state) => {
    return state.assigned
  },

  /**
   * @param {object} state
   * @returns {function(any):boolean}
   */
  isAssigned: (state) => {
    return (id) => !!state.assigned.find((exp) => exp.id === id)
  },

  /**
   * @param {object} state
   * @returns {undefined|object}
   */
  queuedById: (state) => {
    return (id) => state.queued.find((exp) => exp.id === id)
  },

  /**
   * Get a list of experiment codes
   *
   * @param {object} state
   * @returns {Array<string>}
   */
  codeList: (state) => {
    return state.assigned.map((experiment) => experiment.code)
  },

  /**
   * @param {object} state
   * @returns {[]|*}
   */
  activeExperimentList: (state) => {
    return state.experimentList
  },

  /**
   * @param {object} state
   * @returns {[]|boolean|*}
   */
  getOverride: (state) => {
    return state.override
  },
}

export const mutations = {
  /**
   * @param {object} state
   * @param {object} experiment
   */
  addAssigned(state, experiment) {
    const decoratedExperiment = decorateExperiment(experiment)
    state.assigned.push(decoratedExperiment)
    EventBus.emitExperimentSetEvent(decoratedExperiment)
  },

  /**
   * @param {object} state
   * @param {object} experiment
   */
  addQueued(state, experiment) {
    state.queued.push(experiment)
  },

  /**
   * @param {object} state
   * @param {string} id
   */
  removeQueuedForId(state, id) {
    state.queued = state.queued.filter((exp) => exp.id !== id)
  },

  /**
   * @param {{assigned: *[], queued: *[]}} state
   * @param {{assigned: *[], queued: *[]}} newState
   */
  reset(state, newState = getDefaultState()) {
    Object.assign(state, newState)
  },

  /**
   * @param {object} state
   * @param {object} override
   */
  setOverride(state, override) {
    Vue.set(state, 'override', override)
  },

  /**
   * @param {object} state
   * @param {object} experiment
   */
  addOverride(state, experiment) {
    Vue.set(state.override, experiment.id, experiment.override)
  },

  /**
   * @param {object} state
   * @param {string} experimentId
   */
  removeOverride(state, experimentId) {
    Vue.delete(state.override, experimentId)
  },

  /**
   * @param {object} state
   */
  clearActiveExperiments(state) {
    state.experimentList = []
  },

  /**
   * @param {object} state
   * @param {string} experimentName
   */
  addExperiment(state, experimentName) {
    state.experimentList.push(experimentName)
  },
}

export const actions = {
  /**
   * @param {object} context
   * @param {Function} context.commit
   * @param {{assigned: *[], queued: *[]}} context.state
   * @param {object} experiment
   */
  processQueued({ commit, state }, experiment) {
    commit('removeQueuedForId', experiment.id)
    commit('addAssigned', experiment)
  },

  /**
   * @param {object} context
   * @param {object} context.state
   * @param {Function} context.commit
   * @param {Function} context.dispatch
   * @param {{id: string, source: string}} experimentData
   * @returns {object|undefined}
   */
  async get({ state, dispatch, commit }, experimentData) {
    // If we already have an assigned experiment that matches the requested experiment, return that one instead.
    const existingAssignedExperiment = state.assigned.find(
      (exp) => exp.id === experimentData.id,
    )
    if (existingAssignedExperiment) {
      return existingAssignedExperiment
    }

    // We queue experiments, because we only know for ABSmartly if they are assigned until the onEvent callback returns the exposure event.
    await commit('addQueued', experimentData)

    const { id, source } = experimentData
    const variant = await activateExperiment(source, id, state.override)
    const experiment = decorateExperiment({
      ...experimentData,
      variant,
    })
    // Validate if given experiment has required properties
    if (!validateExperiment(experiment)) {
      return undefined
    }

    return experiment
  },
}

/**
 * @param {object} payload
 * @param {string} payload.id                                       - The unique experiment identifier (f.e. -ZiGm8BiS42IBmpGkRVurg).
 * @param {number} payload.abSmartlyId                              - Id used by A/B Smartly
 * @param {string} payload.variant                                  - The variant being shown to the visitor (f.e. 0 for control, 1 for variant 1).
 * @param {string} payload.source                                   - The two letter abbreviation of the tool running our experiments (f.e. GO or AB).
 * @param {number} payload.iteration
 * @returns {object}
 */
export function decorateExperiment({
  id,
  abSmartlyId,
  variant,
  source,
  iteration = 1,
}) {
  variant = String(variant)
  const code = [source, id, variant, iteration].join(':')
  return {
    id,
    abSmartlyId,
    variant,
    source,
    code,
    isTreatment: variant === '1',
  }
}

/**
 * @param {string} id
 * @param {object} override
 * @returns {Promise}
 */
function activateABSmartlyExperiment(id, override) {
  return new Promise((resolve, reject) => {
    Vue.prototype.$absmartly
      .ready()
      .then(() => {
        if (typeof override === 'object') {
          Vue.prototype.$absmartly.overrides(override)
        }

        const variant = Vue.prototype.$absmartly.treatment(id)
        resolve(variant)
      })
      .catch(reject)
  })
}

/**
 * @param {ExperimentSource} source
 * @param {string} id
 * @param {object} override
 * @returns {Promise}
 */
export function activateExperiment(source, id, override) {
  return new Promise((resolve) => {
    let variant = null
    switch (source) {
      case ExperimentSource.ABSMARTLY:
        variant = activateABSmartlyExperiment(id, override)
        break
      default:
        throw new Error(`Experiment source ${source} is not valid.`)
    }

    resolve(variant)
  })
}

/**
 * @param {object} experiment
 * @param {string} experiment.id
 * @param {string|undefined} experiment.variant
 * @returns {boolean}
 */
export function validateExperiment({ id, variant }) {
  if (!id) {
    throw new Error(`Experiment id ${id} is not valid.`)
  }

  // No variant means the experiment is not found or assigned.
  if (variant === undefined || variant === 'undefined') {
    throw new Error(`No experiment has been found for id ${id}.`)
  }

  return true
}

export default {
  state,
  actions,
  mutations,
  getters,
}
