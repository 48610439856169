<template>
  <dl class="t-expansion-panel" :class="[colorVariant]" role="presentation">
    <t-expansion-panel-item
      v-for="(item, index) in items"
      :id="item.title"
      :key="index"
      :item="item"
      :group-id="id"
      :color="color"
      @toggle="onToggle(item)"
    />
  </dl>
</template>

<script>
import TExpansionPanelItem from 'chimera/all/components/elements/TExpansionPanelItem'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TExpansionPanel',

  components: {
    TExpansionPanelItem,
  },

  mixins: [usesColor],

  props: {
    id: {
      type: String,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    /**
     * Allow multiple items to be expanded
     */
    multiple: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    /**
     * @param {object} item
     */
    onToggle(item) {
      this.activateItem(item)
    },

    /**
     * @param {object} activateItem
     * @param {boolean} allowMultiple
     */
    activateItem(activateItem, allowMultiple = this.multiple) {
      this.items.forEach((item) => {
        const isCurrentItem = Object.is(item, activateItem)

        // Toggle when it is the current item
        if (isCurrentItem) {
          item.active = !item.active
        } else if (!allowMultiple) {
          item.active = false
        }
      })
    },
  },
}
</script>
