// NL - Consumer
export const insulationCrawlspaceConsumerNL = {
  id: '534e74ea-0c7d-40c1-8785-49b8985e8d4e',
  identifier: 'insulation-crawlspace-consumer',
}
export const insulationFacadeConsumerNL = {
  id: 'db2aa69e-e5d8-4d1a-aee3-505a05a21e5b',
  identifier: 'insulation-facade-consumer',
}
export const insulationRoofConsumerNL = {
  id: 'b1b5cee2-d5c0-4cda-ab37-063647725dbf',
  identifier: 'insulation-roof-consumer',
}
export const insulationFloorConsumerNL = {
  id: '01e7dfdc-5450-4501-9de5-64cd47c02c88',
  identifier: 'insulation-floor-consumer',
}
export const insulationCavityWallConsumerNL = {
  id: 'db8d11ef-b867-4658-8861-e96dd76dce85',
  identifier: 'insulation-cavity-wall-consumer',
}
export const insulationGlassConsumerNL = {
  id: '306c2d70-4a10-4120-9eb9-bb3336cf2b5f',
  identifier: 'insulation-glass-consumer',
}
export const insulationWallConsumerNL = {
  id: 'f95b2093-5629-4fd5-ba90-034314670ba5',
  identifier: 'insulation-wall-consumer',
}
export const insulationBottomConsumerNL = {
  id: '9adfbd3d-b81a-4753-92e7-b514310d7769',
  identifier: 'insulation-bottom-consumer',
}

// NL - Corporate
export const insulationCrawlspaceCorporateNL = {
  id: '3cc78915-3648-462d-b25b-7bbe8a6a492c',
  identifier: 'insulation-crawlspace-corporate',
}
export const insulationFacadeCorporateNL = {
  id: '068994dd-00f7-42e2-bebf-776a3a753179',
  identifier: 'insulation-facade-corporate',
}
export const insulationRoofCorporateNL = {
  id: 'a2a14a2e-faf0-458e-b2d0-7f48dcef8b5d',
  identifier: 'insulation-roof-corporate',
}
export const insulationFloorCorporateNL = {
  id: 'dd0defd9-8422-47d7-8ecc-1ef04df6c5ef',
  identifier: 'insulation-floor-corporate',
}
export const insulationCavityWallCorporateNL = {
  id: '7aab813c-1a65-453a-b329-5aa0ea1f8ab3',
  identifier: 'insulation-cavity-wall-corporate',
}
export const insulationGlassCorporateNL = {
  id: '17984fe9-a147-4c87-82fc-14c69fca5789',
  identifier: 'insulation-glass-corporate',
}
export const insulationWallCorporateNL = {
  id: '58f61c27-2cea-49eb-bb28-8b825ec408a6',
  identifier: 'insulation-wall-corporate',
}
export const insulationBottomCorporateNL = {
  id: '46d4cc36-f69a-4707-a505-f81baac3ea9b',
  identifier: 'insulation-bottom-corporate',
}
