<template>
  <div
    :id="trackingId"
    v-track-click
    v-track-visibility.once
    class="t-expansion-panel-item"
    :class="[
      {
        't-expansion-panel-item--active': item.active,
      },
      colorVariant,
    ]"
  >
    <dt class="t-expansion-panel-item__header">
      <button class="t-expansion-panel-item__button" @click="$emit('toggle')">
        <span class="t-expansion-panel-item__button-icon" />
        <span class="t-expansion-panel-item__title">
          {{ item.title }}
        </span>
      </button>
    </dt>

    <transition
      name="t-expansion-panel-item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <dd v-if="item.active" class="t-expansion-panel-item__details">
        <div
          class="t-expansion-panel-item__details-inner"
          v-html="item.details"
        />
      </dd>
    </transition>
  </div>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TExpansionPanelItem',

  mixins: [usesColor],

  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },

    groupId: {
      type: [Number, String],
      default: 'default-group',
    },

    color: {
      type: String,
      default: 'secondary',
    },
  },

  computed: {
    /**
     * Create an id for tracking purposes
     *
     * @returns {number | string}
     */
    trackingId() {
      return this.id || this.item.title
    },
  },

  methods: {
    /**
     * @param {Element} element
     */
    startTransition(element) {
      element.style.height = `${element.scrollHeight}px`
    },

    /**
     * @param {Element} element
     */
    endTransition(element) {
      element.style.height = ''
    },
  },
}
</script>
