<template>
  <div
    class="t-stepper-header"
    :class="[{ 't-stepper-header--active': isActive }, colorVariant]"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <div class="t-stepper-header__header">
      <div class="t-stepper-header__image-container">
        <InlineSvg
          :src="step.imagePath"
          :alt="step.title"
          class="t-stepper-header__image"
        />
      </div>

      <div class="t-stepper-header__indicator">
        <span class="indicator__text">
          {{ index }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'TStepperHeader',

  components: {
    InlineSvg,
  },

  mixins: [usesColor],

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    index: {
      type: Number,
      default: 0,
    },

    step: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
