import Vue from 'vue'
import { EventBus } from 'chimera/all/plugins/eventbus'

/**
 * Tracking visibility of elements.
 */
export default () => {
  Vue.directive('trackVisibility', {
    /**
     * @param {HTMLElement} element
     * @param {object} binding
     */
    inserted(element, binding) {
      const modifiers = binding.modifiers || {}
      const value = binding.value
      const isObject = typeof value === 'object'
      const callback = isObject ? value.handler : value

      const observer = new IntersectionObserver(
        (entries = [], observer) => {
          /* istanbul ignore if */
          if (!element._observe) {
            return
          } // Just in case, should never fire

          // If is not quiet or has already been initialised, invoke the user callback
          if (!modifiers.quiet || element._observe.init) {
            const isIntersecting = Boolean(
              entries.find((entry) => entry.isIntersecting),
            )

            // Only callback when provided
            if (callback) {
              callback(entries, observer, isIntersecting)
            }

            trackVisibility(element, isIntersecting)
          }

          // If has already been initialised and has the once modifier, unbind
          if (element._observe.init && modifiers.once) {
            unbindIntersect(element)
          } else {
            // Otherwise, mark the observer as initialised
            element._observe.init = true
          }
        },
        (value && value.options) || {},
      )

      element._observe = { init: false, observer }
      observer.observe(element)
    },

    /**
     * @param {HTMLElement} element
     */
    unbind: unbindIntersect,
  })
}

/**
 * @param {HTMLElement} element
 * @param {boolean} isVisible
 */
function trackVisibility(element, isVisible = false) {
  if (!isVisible) {
    return // TODO We have to discuss if we want to emit on both visible and invisible.
  }
  const who = element.id
  EventBus.emitVisibilityEvent(who, isVisible ? 'yes' : 'no')
}

/**
 * @param {HTMLElement} element
 */
function unbindIntersect(element) {
  if (!element._observe) {
    return
  }

  element._observe.observer.unobserve(element)
  delete element._observe
}
