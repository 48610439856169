import phoneParser from 'chimera/all/functions/phoneParser'
import { trackServerSidePurchase } from './../trackServerside'

/**
 * Tracks a purchase as a Meta Service Goal
 *
 * @param {object} transaction
 * @param {string} transaction.id
 * @param {number} transaction.revenueMade
 * @param {object} transaction.service
 * @param {object} transaction.leadData
 * @param {object} transaction.scope
 * @param transaction.leadData
 * @example {
 *   id: '8303de5c-c2bc-4329-be41-7f82572b863b',
 *   revenueMade: 15.50,
 *   defaultPrice: 5,
 *   leadData: {
 *    name: '',
 *    phone: '',
 *    email: '',
 *    'address-city': '',
 *    'address-postal': '',
 *    'fbp': '',
 *    'fbc': '',
 *   },
 *   service: {
 *     id: '492e346e-80ca-42bc-b7ca-3fd1eb37f45c',
 *     identifier: 'branch-category-consumer'
 *   }
 * }
 */
export function trackPurchase({ id, revenueMade, service, leadData, scope }) {
  if (id === undefined || revenueMade === undefined || service === undefined) {
    const serviceIdentifier =
      !service || !service.identifier
        ? 'unknown-service-identifier'
        : service.identifier
    throw new TypeError(
      `Received transaction event with id: ${id}, revenueMade: ${revenueMade} service: ${serviceIdentifier}`,
    )
  }

  window.fbq(
    'track',
    'Purchase',
    {
      value: revenueMade,
      currency: 'EUR',
    },
    { eventID: id },
  )

  const eventId = id
  const eventSourceUrl = scope.href

  const {
    phone,
    name,
    email,
    country,
    'address-city': city,
    'address-postal': postal,
  } = leadData

  const [firstName = '', ...rest] = (name || '').split(' ')
  const lastName = rest.join(' ')
  const parsedPhoneNumber = phoneParser(phone, country).number || phone
  const userData = {
    fn: firstName,
    ln: lastName,
    em: email,
    ph: parsedPhoneNumber,
    ct: city,
    zp: postal,
    country,
  }

  if (leadData['facebook-cookie-fbc']) {
    userData.fbc = leadData['facebook-cookie-fbc']
  }

  if (leadData['facebook-cookie-fbp']) {
    userData.fbp = leadData['facebook-cookie-fbp']
  }

  const customData = {
    value: revenueMade,
    currency: 'EUR',
  }

  trackServerSidePurchase(eventId, eventSourceUrl, userData, customData)
}
