import { createScopedEvent } from 'chimera/all/plugins/tracking/google/events/analytics/index'

/**
 * @param {object} Vue
 * @param {object} event
 * @param {object} event.scope
 * @param {string} event.who
 * @example {
 *   who: CookiePageLink,
 *   what: undefined|yes|no,
 *   scope: scopeObject
 * }
 */
export function trackVisibility(Vue, { scope, who } = {}) {
  // Assert that our event is received correctly.
  if (who === undefined) {
    throw new TypeError(`Received visibility Ga4 event with who ${who}`)
  }

  Vue.$gtag.event(
    'visibility',
    createScopedEvent(scope, {
      element_id: who,
    }),
  )
}
