// NL - Consumer
export const realEstatePurchaseConsumerNL = {
  id: '14bda032-4132-426a-99ab-4a379986e9a5',
  identifier: 'real-estate-purchase-consumer',
}
export const realEstateSalesConsumerNL = {
  id: 'f0f91850-fb49-4088-9664-23b28a3400d3',
  identifier: 'real-estate-sales-consumer',
}
export const realEstateAppraisalConsumerNL = {
  id: 'd84636e7-ebc5-4cc4-b7a8-d9d96923f094',
  identifier: 'real-estate-appraisal-consumer',
}
export const realEstateRentConsumerNL = {
  id: 'a7800087-858c-4db8-a632-26a1fdad4605',
  identifier: 'real-estate-rent-consumer',
}
export const realEstateRentOutConsumerNL = {
  id: 'c1376a53-ed08-47ea-882f-08fb510c2699',
  identifier: 'real-estate-rent-out-consumer',
}

export const realEstateValuationConsumerNL = {
  id: 'ca3a1118-c2ae-423c-9579-03d2612d2533',
  identifier: 'real-estate-valuation-consumer',
}

// NL - Corporate
export const realEstatePurchaseCorporateNL = {
  id: '80b6f6a9-66dc-437b-b53a-470bb2b39c41',
  identifier: 'real-estate-purchase-corporate',
}
export const realEstateSalesCorporateNL = {
  id: '7cdb1d74-2d05-4c2b-adfe-8ad904e96fba',
  identifier: 'real-estate-sales-corporate',
}
export const realEstateAppraisalCorporateNL = {
  id: '61a2b0a6-a249-46be-b487-eb4d39a5bf2c',
  identifier: 'real-estate-appraisal-corporate',
}
export const realEstateRentCorporateNL = {
  id: '647c7342-5d74-47ea-9b32-5a4621a06d48',
  identifier: 'real-estate-rent-corporate',
}
export const realEstateRentOutCorporateNL = {
  id: 'c001acc9-e642-40cc-936d-83160267ce4f',
  identifier: 'real-estate-rent-out-corporate',
}

export const realEstateValuationCorporateNL = {
  id: '006595da-d6de-4a7c-8fef-ec36ff1af4d1',
  identifier: 'real-estate-valuation-corporate',
}

// BE - Consumer
export const realEstatePurchaseConsumerBE = {
  id: 'beb60ecd-c60c-4e64-bb90-d24615c3c8f4',
  identifier: 'real-estate-purchase-consumer',
}
export const realEstateSalesConsumerBE = {
  id: '84fbc61e-e7b2-4ece-9ad6-6327ab62ba97',
  identifier: 'real-estate-sales-consumer',
}
export const realEstateAppraisalConsumerBE = {
  id: '28eec60f-3286-4914-87af-3a13f22e30cc',
  identifier: 'real-estate-appraisal-consumer',
}
export const realEstateRentConsumerBE = {
  id: '5eda4a53-83b8-483d-a175-0a609fc5ef25',
  identifier: 'real-estate-rent-consumer',
}
export const realEstateRentOutConsumerBE = {
  id: 'd3ba0a87-e3f1-41e8-859c-218726ead7e6',
  identifier: 'real-estate-rent-out-consumer',
}

// BE - Corporate
export const realEstatePurchaseCorporateBE = {
  id: '06d207a5-cce3-410c-833b-20efa46a3c37',
  identifier: 'real-estate-purchase-corporate',
}
export const realEstateSalesCorporateBE = {
  id: 'b482c8c7-1bc2-4262-8844-8c5c285fcf71',
  identifier: 'real-estate-sales-corporate',
}
export const realEstateAppraisalCorporateBE = {
  id: 'a1343cbb-ffe6-4aab-9814-c29f4edaae61',
  identifier: 'real-estate-appraisal-corporate',
}
export const realEstateRentCorporateBE = {
  id: 'cb634e58-389d-4bbf-ab1f-cfa72e17f201',
  identifier: 'real-estate-rent-corporate',
}
export const realEstateRentOutCorporateBE = {
  id: '889f8b6d-c789-45f2-9d9d-1ef36cc3da41',
  identifier: 'real-estate-rent-out-corporate',
}

// DE - Consumer
export const realEstatePurchaseConsumerDE = {
  id: 'a8878881-47e1-4173-b285-21d5ede1d256',
  identifier: 'real-estate-purchase-consumer',
}
export const realEstateSalesConsumerDE = {
  id: '29e979d6-f9f0-457b-9093-421718ed3c98',
  identifier: 'real-estate-sales-consumer',
}
export const realEstateAppraisalConsumerDE = {
  id: 'e42f91a9-ee7a-41d0-96a4-ff1529161bae',
  identifier: 'real-estate-appraisal-consumer',
}
export const realEstateRentConsumerDE = {
  id: 'd07e9cf4-9639-4b54-bbc7-04b6df05d2d8',
  identifier: 'real-estate-rent-consumer',
}
export const realEstateRentOutConsumerDE = {
  id: 'cbdfe482-11c4-48fc-a3dc-ed5f5fa9c618',
  identifier: 'real-estate-rent-out-consumer',
}

// DE - Corporate
export const realEstatePurchaseCorporateDE = {
  id: '375b1332-3741-4ca3-8f70-a70fc54d7b4b',
  identifier: 'real-estate-purchase-corporate',
}
export const realEstateSalesCorporateDE = {
  id: '53f3ea28-e45f-4f98-bf9b-2d56304c4df3',
  identifier: 'real-estate-sales-corporate',
}
export const realEstateAppraisalCorporateDE = {
  id: '1ec2ff5a-42f5-4358-97eb-658594f3caba',
  identifier: 'real-estate-appraisal-corporate',
}
export const realEstateRentCorporateDE = {
  id: '733e03e8-3023-41f3-8891-7f6e8649aaa6',
  identifier: 'real-estate-rent-corporate',
}
export const realEstateRentOutCorporateDE = {
  id: '1e1ca2ec-68da-48b9-9c69-fae535fe84d8',
  identifier: 'real-estate-rent-out-corporate',
}

// ES - Consumer
export const realEstatePurchaseConsumerES = {
  id: '9b013907-4617-446a-a304-b3f292e62830',
  identifier: 'real-estate-purchase-consumer',
}
export const realEstateSalesConsumerES = {
  id: '9b013907-461b-48a8-8e42-794328b32dac',
  identifier: 'real-estate-sales-consumer',
}
export const realEstateAppraisalConsumerES = {
  id: '9b013907-461e-42ad-9628-0d30482df2cb',
  identifier: 'real-estate-appraisal-consumer',
}

// ES - Corporate
export const realEstatePurchaseCorporateES = {
  id: '9b013907-4619-4087-8df8-6ca6eb7f0a02',
  identifier: 'real-estate-purchase-corporate',
}
export const realEstateSalesCorporateES = {
  id: '9b013907-461d-4057-ab19-41f09d4a3bda',
  identifier: 'real-estate-sales-corporate',
}
export const realEstateAppraisalCorporateES = {
  id: '9b013907-4620-4ba4-b40b-c4b35ba87507',
  identifier: 'real-estate-appraisal-corporate',
}

// FR - Consumer
export const realEstatePurchaseConsumerFR = {
  id: '683ee72e-4b6d-4b71-bf08-795fa0c6976b',
  identifier: 'real-estate-purchase-consumer',
}
export const realEstateSalesConsumerFR = {
  id: '30efdc46-2528-4538-8e21-2c6a3c24809a',
  identifier: 'real-estate-sales-consumer',
}
export const realEstateAppraisalConsumerFR = {
  id: '5eb2dc4e-3ffa-4dfe-a57c-b405a398b600',
  identifier: 'real-estate-appraisal-consumer',
}
export const realEstateRentConsumerFR = {
  id: '9fe96532-6b5a-44bf-a515-fed9b822399f',
  identifier: 'real-estate-rent-consumer',
}
export const realEstateRentOutConsumerFR = {
  id: '332521de-50cd-4adf-8c4d-c0860efbce8b',
  identifier: 'real-estate-rent-out-consumer',
}

// FR - Corporate
export const realEstatePurchaseCorporateFR = {
  id: '87a7af6a-7d16-4656-ae9f-7f01fa0a03b8',
  identifier: 'real-estate-purchase-corporate',
}
export const realEstateSalesCorporateFR = {
  id: 'c12e898e-b82f-4656-a219-8104b8523ab6',
  identifier: 'real-estate-sales-corporate',
}
export const realEstateAppraisalCorporateFR = {
  id: 'f35acf62-40da-4f7a-a0fd-8d5bde332394',
  identifier: 'real-estate-appraisal-corporate',
}
export const realEstateRentCorporateFR = {
  id: 'a94fe113-af39-47fd-b2ec-5db13355227d',
  identifier: 'real-estate-rent-corporate',
}
export const realEstateRentOutCorporateFR = {
  id: '3d5aa8b6-87a1-4321-aef4-5cdbb93592f7',
  identifier: 'real-estate-rent-out-corporate',
}

// IT - Consumer
export const realEstatePurchaseConsumerIT = {
  id: 'f8ecc07d-57cb-449b-bfbe-faeaea2c79d5',
  identifier: 'real-estate-purchase-consumer',
}
export const realEstateSalesConsumerIT = {
  id: '19905892-6113-403e-8fa9-30cb6d069feb',
  identifier: 'real-estate-sales-consumer',
}
export const realEstateAppraisalConsumerIT = {
  id: 'e8fb9ca6-d6f8-4253-b069-7c24ab3842c0',
  identifier: 'real-estate-appraisal-consumer',
}
export const realEstateRentConsumerIT = {
  id: 'ec19d4ae-7ccf-4c93-a187-6147d682444e',
  identifier: 'real-estate-rent-consumer',
}
export const realEstateRentOutConsumerIT = {
  id: '3b0887bf-559f-4b61-a3b2-c855251ce1c8',
  identifier: 'real-estate-rent-out-consumer',
}

// IT - Corporate
export const realEstatePurchaseCorporateIT = {
  id: 'e527b01a-b82b-48d2-9136-31b49e602e8f',
  identifier: 'real-estate-purchase-corporate',
}
export const realEstateSalesCorporateIT = {
  id: '6b0a73c1-44f5-4987-b4c5-6d163ccfb94d',
  identifier: 'real-estate-sales-corporate',
}
export const realEstateAppraisalCorporateIT = {
  id: '86efe1b1-31af-4d6f-9dd7-221835126a6e',
  identifier: 'real-estate-appraisal-corporate',
}
export const realEstateRentCorporateIT = {
  id: 'e8572237-fd69-4a19-ae86-e0749b00f3f6',
  identifier: 'real-estate-rent-corporate',
}
export const realEstateRentOutCorporateIT = {
  id: '0ebcba32-d44d-40b6-8ad1-51a58d6c9387',
  identifier: 'real-estate-rent-out-corporate',
}
