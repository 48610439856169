import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'
import TAlert from 'chimera/all/components/elements/TAlert'
import TBtn from 'chimera/all/components/elements/TBtn'
import TBtnLink from 'chimera/all/components/elements/TBtnLink'
import TCard from 'chimera/all/components/elements/TCard'
import TDatePicker from 'chimera/all/components/elements/TDatePicker'
import TDialog from 'chimera/all/components/elements/TDialog'
import TForm from 'chimera/all/components/elements/TForm'
import TIcon from 'chimera/all/components/elements/TIcon'
import TProgressBar from 'chimera/all/components/elements/TProgressBar'
import TRating from 'chimera/all/components/elements/TRating'
import TSelectable from 'chimera/all/components/elements/TSelectable'
import TSelectableWithTextField from 'chimera/all/components/elements/TSelectableWithTextField'
import TStepper from 'chimera/all/components/elements/TStepper'
import TStepperHeader from 'chimera/all/components/elements/TStepperHeader'
import TTable from 'chimera/all/components/elements/TTable'
import TTextarea from 'chimera/all/components/elements/TTextarea'
import TTextField from 'chimera/all/components/elements/TTextField'
import TExpansionPanel from 'chimera/all/components/elements/TExpansionPanel'
import AbstractFormPart from 'chimera/all/components/form/parts/AbstractFormPart'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormStepSubmitButton from 'chimera/all/components/form/steps/FormStepSubmitButton'
import Tooltip from 'chimera/all/components/Tooltip'

/**
 * Make our components easily accessible without any imports
 */
// Custom elements
Vue.component(TAlert.name, TAlert)
Vue.component(TBtn.name, TBtn)
Vue.component(TBtnLink.name, TBtnLink)
Vue.component(TCard.name, TCard)
Vue.component(TForm.name, TForm)
Vue.component(TIcon.name, TIcon)
Vue.component(TProgressBar.name, TProgressBar)
Vue.component(TRating.name, TRating)
Vue.component(TSelectable.name, TSelectable)
Vue.component(TSelectableWithTextField.name, TSelectableWithTextField)
Vue.component(TStepper.name, TStepper)
Vue.component(TStepperHeader.name, TStepperHeader)
Vue.component(TTable.name, TTable)
Vue.component(TTextField.name, TTextField)
Vue.component(TTextarea.name, TTextarea)
Vue.component(TDatePicker.name, TDatePicker)
Vue.component(TDialog.name, TDialog)
Vue.component(TExpansionPanel.name, TExpansionPanel)
Vue.component(Tooltip.name, Tooltip)

// Form
Vue.component(AbstractFormPart.name, AbstractFormPart)
Vue.component(AbstractFormStep.name, AbstractFormStep)
Vue.component(FormStepSubmitButton.name, FormStepSubmitButton)

/**
 * Vue wrapper for TippyJS
 *
 * @see https://atomiks.github.io/tippyjs/
 * @see https://kabbouchi.github.io/vue-tippy
 */
Vue.use(VueTippy)
Vue.component('Tippy', TippyComponent)
