// NL
export const movingConsumerNL = {
  id: 'b2398882-9dbd-451d-837b-501976a90a9f',
  identifier: 'moving-consumer',
}
export const movingCorporateNL = {
  id: 'fc708b3b-ba4b-41c4-a420-7b90552bc553',
  identifier: 'moving-corporate',
}
export const movingInternationalNL = {
  id: '47a53bcd-4728-47f1-a159-c1537c7b3c82',
  identifier: 'moving-international',
}

// BE
export const movingConsumerBE = {
  id: 'efd7831e-ed68-41fb-8dc4-46482aa4552b',
  identifier: 'moving-consumer',
}
export const movingCorporateBE = {
  id: '0f942727-8fac-4e07-8f1c-83a8485c9712',
  identifier: 'moving-corporate',
}
export const movingInternationalBE = {
  id: '3761e5a4-4e74-4a7d-a07d-ef15faca24fe',
  identifier: 'moving-international',
}

// ES
export const movingDefaultES = {
  id: 'dd3b3760-b4b1-4269-924d-dd629a02c324',
  identifier: 'moving-consumer',
}
export const movingInternationalES = {
  id: 'fa98cf2d-4bd7-415e-bb68-d0e156018ac5',
  identifier: 'moving-international',
}
