import { emit } from 'chimera/all/plugins/eventbus/events/emit'
import { bindOn } from 'chimera/all/plugins/eventbus/events/bind'
import { getEventScope } from 'chimera/all/plugins/eventbus/scope'

export const leadStoreUpdateEvent = 'eb_leadStoreUpdate'

/**
 * @param {object} data
 * @returns {void}
 **/
export function emitLeadStoreUpdateEvent(data) {
  emit(leadStoreUpdateEvent, {
    data,
    scope: getEventScope(),
  })
}

/**
 * @param {Function} callback
 * @param {object} callback.event
 * @param {object} callback.event.data
 * @param {string} callback.event.scope
 * @returns {void}
 */
export function onLeadStoreUpdateEvent(callback) {
  bindOn(leadStoreUpdateEvent, callback)
}
