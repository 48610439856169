import Vue from 'vue'
import ABSmartlyTracking from 'chimera/all/plugins/tracking/absmartly'
import { EventBus } from 'chimera/all/plugins/eventbus'

export default ({ env, store }) => {
  const options = {
    apiKey: env.abSmartlyAPIKey,
    sessionId: store.getters['context/get']('sessionId'),
    visitorId: store.getters['context/get']('visitorId'),
    country: store.getters['context/get']('country'),
    language: store.getters['context/get']('language'),
    environment: process.env.environment,
    application: process.env.concept,
    onEvent: (context, eventName, data) => {
      onEventCallback(store, context, eventName, data)
    },
  }

  try {
    Vue.use(ABSmartlyTracking, options)
  } catch (error) {
    EventBus.emitErrorAppErrorEvent(error, options)
  }
}

/**
 * @param {object} store
 * @param {object} context
 * @param {string} eventName
 * @param {object} data
 */
export function onEventCallback(store, context, eventName, data) {
  if (process.env.environment !== 'production') {
    // eslint-disable-next-line no-console
    console.log(`ABSmartly ${eventName}:`, { data })

    if (eventName === 'publish' && data.goals === undefined) {
      const experimentList = [
        ...(data.exposures || []),
        ...(data.experiments || []),
      ]

      store.commit('experiments/clearActiveExperiments')
      experimentList.forEach((experiment, i) => {
        store.commit('experiments/addExperiment', experiment.name)
      })
    }
  }

  switch (eventName) {
    case 'exposure':
      onExposureEvent(store, data, context)
      if (process.env.environment !== 'production') {
        // eslint-disable-next-line no-console
        console.table(data)
      }
      break
  }
}

/**
 * @param {object} store
 * @param {object} data
 * @param {boolean} data.assigned
 * @param {string} data.name
 * @param {string|number} data.variant
 * @param {number} data.id
 * @param {boolean} data.eligible
 * @param {object} context
 */
export async function onExposureEvent(
  store,
  { assigned, eligible, name, variant, id },
  context,
) {
  if (!assigned || !eligible) {
    store.commit('experiments/removeQueuedForId', name)
    return
  }

  let iteration
  const exposedExperiment = context
    .data()
    .experiments.find((experiment) => experiment.id === id)
  if (exposedExperiment.iteration) {
    iteration = exposedExperiment.iteration
  }

  const queuedExperiment = await store.getters['experiments/queuedById'](name)
  store.dispatch('experiments/processQueued', {
    ...queuedExperiment,
    variant: String(variant),
    abSmartlyId: id,
    iteration,
  })
}
