<template>
  <div id="app">
    <NavigationBar class="navbar--light-logo" logo-src="images/logo.svg" />

    <main>
      <nuxt />
    </main>

    <Footer class="mt-auto" />
  </div>
</template>

<script>
import NavigationBar from 'chimera/all/themes/waffle/components/layout/NavigationBar'
import Footer from 'chimera/all/themes/waffle/components/layout/Footer'

export default {
  name: 'DefaultLayout',

  components: {
    Footer,
    NavigationBar,
  },
}
</script>
