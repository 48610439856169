import * as events from './events'
import install from './install'

export default install

export const EventBus = {
  onPageViewEvent: events.onPageViewEvent,
  onceOnPageViewEvent: events.onceOnPageViewEvent,
  emitPageViewEvent: events.emitPageViewEvent,
  onClickEvent: events.onClickEvent,
  emitClickEvent: events.emitClickEvent,
  emitClickEventInScope: events.emitClickEventInScope,
  onDialogEvent: events.onDialogEvent,
  emitDialogEvent: events.emitDialogEvent,
  onJsErrorEvent: events.onJsErrorEvent,
  emitJsErrorEvent: events.emitJsErrorEvent,
  onAppErrorEvent: events.onAppErrorEvent,
  emitWarningAppErrorEvent: events.emitWarningAppErrorEvent,
  emitErrorAppErrorEvent: events.emitErrorAppErrorEvent,
  emitFatalAppErrorEvent: events.emitFatalAppErrorEvent,
  onFormFieldErrorEvent: events.onFormFieldErrorEvent,
  emitFormFieldErrorEvent: events.emitFormFieldErrorEvent,
  onFormFieldValidEvent: events.onFormFieldValidEvent,
  emitFormFieldValidEvent: events.emitFormFieldValidEvent,
  onFormSubmitErrorEvent: events.onFormSubmitErrorEvent,
  emitFormSubmitErrorEvent: events.emitFormSubmitErrorEvent,
  onServiceSelectionEvent: events.onServiceSelectionEvent,
  emitServiceSelectionEvent: events.emitServiceSelectionEvent,
  onTransactionEvent: events.onTransactionEvent,
  emitTransactionEvent: events.emitTransactionEvent,
  emitTransactionEventInScope: events.emitTransactionEventInScope,
  onCheckoutStepEvent: events.onCheckoutStepEvent,
  emitCheckoutStepEvent: events.emitCheckoutStepEvent,
  onBeginCheckoutEvent: events.onBeginCheckoutEvent,
  onPostalNotAutoCompletedEvent: events.onPostalNotAutoCompletedEvent,
  emitPostalNotAutoCompletedEvent: events.emitPostalNotAutoCompletedEvent,
  onPostalNotFoundEvent: events.onPostalNotFoundEvent,
  emitPostalNotFoundEvent: events.emitPostalNotFoundEvent,
  onVisibilityEvent: events.onVisibilityEvent,
  emitVisibilityEvent: events.emitVisibilityEvent,
  onConversionEvent: events.onConversionEvent,
  emitConversionEventInScope: events.emitConversionEventInScope,
  emitConversionEvent: events.emitConversionEvent,
  emitAbandonmentEvent: events.emitAbandonmentEvent,
  onAbandonmentEvent: events.onAbandonmentEvent,
  onExperimentSetEvent: events.onExperimentSetEvent,
  emitExperimentSetEvent: events.emitExperimentSetEvent,
  onFormEnterEvent: events.onFormEnterEvent,
  emitFormEnterEvent: events.emitFormEnterEvent,
  onFormLeaveEvent: events.onFormLeaveEvent,
  emitFormLeaveEvent: events.emitFormLeaveEvent,
  onFormNavigationEvent: events.onFormNavigationEvent,
  emitFormNavigationEvent: events.emitFormNavigationEvent,
  onTransitionEvent: events.onTransitionEvent,
  emitTransitionEvent: events.emitTransitionEvent,
  emitTransitionEventInScope: events.emitTransitionEventInScope,
  onConsentEvent: events.onConsentEvent,
  emitConsentEvent: events.emitConsentEvent,
  onRecaptchaTokenLengthEvent: events.onRecaptchaTokenLengthEvent,
  emitRecaptchaTokenLengthEvent: events.emitRecaptchaTokenLengthEvent,
  onCoreWebVitalEvent: events.onCoreWebVitalEvent,
  emitCoreWebVitalEvent: events.emitCoreWebVitalEvent,
  onLeadStoreUpdateEvent: events.onLeadStoreUpdateEvent,
  emitLeadStoreUpdateEvent: events.emitLeadStoreUpdateEvent,
}
